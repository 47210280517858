import React, { ReactNode } from 'react';

interface IAuthLayoutProps {
  image?: string;
  children: ReactNode;
  logo?: string;
  className?: string;
}

export const AuthLayout = (props: IAuthLayoutProps) => {
  var emptyBg: Boolean = false;

  if (props.image != null) {
    emptyBg = !emptyBg;
  }

  const { image, children, logo, className } = props;
  return (
    <div
      className={`${className} login-layout`}
      style={{
        background: emptyBg
          ? 'url("' + image + '") no-repeat center center fixed'
          : '#212121',
      }}
    >
      <div className="auth-box">
        <img src={logo} alt="" className="logo" loading="lazy" />
        {children}
      </div>
    </div>
  );
};
